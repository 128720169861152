import React from "react";

import BannerWrapperCustomImg from "../../top_banner/banner_wrapper_custom_img";
import { useTranslation } from 'react-i18next';

const LegalNotice = () => {
  const {t} = useTranslation();
  return (
    <div className={`conatiner font-sans`}>
      <BannerWrapperCustomImg
        largeBgImg={'https://d2lxkizvrhu4im.cloudfront.net/images/background-cookie-banner.png'}
        nonlargeBgImg={'https://d2lxkizvrhu4im.cloudfront.net/images/background-cookie-banner.png'}
      >
        <div className=" flex flex-col  justify-center items-center h-full py-20  mx-auto">
          <div class="font-bold text-3xl md:text-6xl text-center">
            <h1 className='uppercase'>{t('terms_of_use')}</h1>
          </div>
          <div class=" text-sm md:text-xl  font-medium  text-center  lg:text-left leading-relaxed px-1 md:px-0">
            {t('last_updated_on_may_2_2022')}
          </div>
        </div>
      </BannerWrapperCustomImg>

      <div className={`mx-auto w-11/12 lg:w-5/6 mb-16`}>
        <div className="my-8 font-bold">
          {t('website_editor')}
        </div>
        <div className="">
          {t('company_name_of_the_publisher')} Mereos<br />
          {t('status_of_the_company')}<br />
          RCS : Paris n°820 844 348<br />
          {t('vat_n')} : FR70820844348<br />
          {t('share_capital')} : €21 740,00<br />
          {t('the_head_office')} : 86 rue Jouffroy d’Abbans, 75017 Paris<br />
          {t('email_address')} : contact@mereos.eu<br /> 
          {t('legal_representative')} : Dorone Parienti, Président<br />
          {t('name_of_publication_director')} : Dorone Parienti, Président
        </div>
        <div className="my-8 font-bold">
          {t('website_host')}
        </div>
        <div className="">
          OVH<br />
          2, rue Kellermann BP 80157 59053 ROUBAIX Cedex 1<br />
          Tél. : +33 (0)8 203 203 63<br />
          <a
            href='https://ovhcloud.com'
            className="text-blue-600"
            target={'_blank'}
          >
            {' https://ovhcloud.com'}
          </a>
        </div>
      </div>
    </div>
  );
};

export default LegalNotice;
